import React, { useState } from 'react';
import styled from 'styled-components';

import RefferalSvg from '../../../asset/svg/common/refferal.svg';
import { TextElem } from '../../../common/text';
import { Spacing } from '../../../theme';
import {
  USER_ITEM_DATA_RAW_INTER,
  USER_ROLE_OPTION_LIST,
} from '../../../data/user/constant';
import { USER_ROLE, USER_ROLE_VIEW } from '../../../data/auth/constant';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../../theme/value';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { UserItemUpdateContainer } from '../../user-item-update';
import { SelectElem } from '../../../common/select';
import { CopyField } from './copy-elem';
import { SKILL_VIEW } from '../../profile-update/constant';
import { GIFT_DATA } from '../../gift-user-list/constant';
import { USER_LVL_OPTION_LIST } from '../../../data/level/constant';

export const CardUser: React.FC<{
  data?: USER_ITEM_DATA_RAW_INTER;
  isOpen: boolean;
  onClick: () => void;
}> = ({ data, isOpen, onClick }) => {
  const [selectValue, setSelectValue] = useState<{
    role?: number;
    level?: number;
  }>({});

  const id = data?.telegramId;
  const fullName = `${data?.firstName} ${data?.lastName}`;
  const userName = data?.userName;
  const defaultRoleValue = USER_ROLE_OPTION_LIST.find(
    (e) => e.value === data?.role,
  );
  const defaultLvlValue = USER_LVL_OPTION_LIST.find(
    (e) => e.value === data?.level,
  );

  const onChangeSelect = (_name: string, values: any) => {
    setSelectValue((prevState) => ({
      ...prevState,
      [_name]: values.value,
    }));
  };

  return (
    <div>
      <Container className="user_container" isOpen={isOpen} onClick={onClick}>
        <UserContainer>
          <InfoContainer>
            <TextElem
              size="semiSmall"
              color="white"
              type="semi-bold"
              tid={`ID ${id || ''}`}
            />
            <TextElem
              color="textSecondary"
              size="small"
              type="medium"
              tid={userName}
            />
          </InfoContainer>
        </UserContainer>

        <TextElem
          color="default"
          size="semiSmall"
          type="semi-bold"
          tid="USER.LIST.LVL"
          tvalue={{ value: data?.level }}
        />
        {!isOpen && (
          <RoleContainer>
            <TextElem
              color="white"
              size="small"
              type="medium"
              tid={USER_ROLE_VIEW[data?.role || USER_ROLE.USER]}
            />
          </RoleContainer>
        )}
        {isOpen && (
          <RoleButtonContainer>
            <UserItemUpdateContainer userId={data?.id} formData={selectValue} />
          </RoleButtonContainer>
        )}
      </Container>
      <>
        {isOpen && (
          <ExpandedContent>
            <GridContaienr>
              <SelectElem
                name="role"
                options={USER_ROLE_OPTION_LIST}
                onChange={onChangeSelect}
                defaultValue={defaultRoleValue}
              />
              <SelectElem
                name="level"
                options={USER_LVL_OPTION_LIST}
                onChange={onChangeSelect}
                defaultValue={defaultLvlValue}
              />
            </GridContaienr>
            <GridContaienr>
              <ChipContainer>
                <TextElem
                  color="textSecondary"
                  size="small"
                  type="medium"
                  tid="USER.LIST.NAME"
                />
                <TextElem
                  color="textThird"
                  size="small"
                  type="medium"
                  tid={fullName}
                />
              </ChipContainer>
              <ChipContainer>
                <TextElem
                  color="textSecondary"
                  size="small"
                  type="medium"
                  tid="USER.LIST.LANGUAGE"
                />
                <TextElem
                  color="textThird"
                  size="small"
                  type="medium"
                  tid={data?.languageCode.toUpperCase()}
                />
              </ChipContainer>
              <CopyField value={String(id)} tid="USER.LIST.TELEGRAM_ID" />
              <CopyField value={data?.userName} tid="USER.LIST.NICKNAME" />
              <CopyField value={data?.phone} tid="USER.LIST.PHONE" />
              <CopyField value={data?.fullName} tid="USER.LIST.FULL_NAME" />

              <ChipContainer>
                <TextElem
                  color="textSecondary"
                  size="small"
                  type="medium"
                  tid="USER.LIST.BALANCE"
                />
                <TextElem
                  color="textThird"
                  size="small"
                  type="medium"
                  tid={`$ ${data?.balance}`}
                />
              </ChipContainer>
              <ChipContainer>
                <TextElem
                  color="textSecondary"
                  size="small"
                  type="medium"
                  tid="USER.LIST.TOTAL_BONUS"
                />
                <TextElem
                  color="textThird"
                  size="small"
                  type="medium"
                  tid={`$ ${data?.totalBalance}`}
                />
              </ChipContainer>
              <ChipContainer>
                <TextElem
                  color="textSecondary"
                  size="small"
                  type="medium"
                  tid="USER.LIST.WAITING"
                />
                <TextElem
                  color="textThird"
                  size="small"
                  type="medium"
                  tid={`$ ${data?.pendingBalance}`}
                />
              </ChipContainer>
              <ChipContainer>
                <TextElem
                  color="textSecondary"
                  size="small"
                  type="medium"
                  tid="USER.LIST.REFERRAL"
                />
                <FlexContainer>
                  <RefferalImg alt="refferal image" src={RefferalSvg} />
                  {'  '}
                  <TextElem
                    color="textThird"
                    size="small"
                    type="medium"
                    tid={`${data?.referalUser}`}
                  />
                </FlexContainer>
              </ChipContainer>
            </GridContaienr>
            <CopyField value={data?.email} tid="USER.LIST.EMAIL" />

            <ChipContainer>
              <TextElem
                color="textSecondary"
                size="small"
                type="medium"
                tid="USER.LIST.GIFT"
              />
              <GiftContainer>
                {Array.isArray(data?.gift) &&
                  data?.gift &&
                  data.gift.length > 0 &&
                  data.gift.map((e, index) => (
                    <>
                      <TextElem
                        color="textThird"
                        size="small"
                        type="medium"
                        tid={`${GIFT_DATA[e].title}`}
                      />
                      {index !== data.gift.length - 1 && <Dot />}
                    </>
                  ))}

                {!data?.gift && (
                  <TextElem
                    color="textThird"
                    size="small"
                    type="medium"
                    tid="USER.LIST.GIFT_EMPTY"
                  />
                )}
              </GiftContainer>
            </ChipContainer>
            <ChipContainer>
              <TextElem
                color="textSecondary"
                size="small"
                type="medium"
                tid="USER.LIST.INVITED"
              />
              {data?.invited && (
                <InvitedContainer>
                  <TextElem
                    color="textThird"
                    size="small"
                    type="medium"
                    tid={`${data?.invited?.userName}`}
                  />
                  <Dot />
                  <TextElem
                    color="textThird"
                    size="small"
                    type="medium"
                    tid={`${data?.invited.telegramId}`}
                  />
                </InvitedContainer>
              )}
              {!data?.invited && (
                <InvitedContainer>
                  <TextElem
                    color="textThird"
                    size="small"
                    type="medium"
                    tid="USER.LIST.NOT_INVITED"
                  />
                </InvitedContainer>
              )}
            </ChipContainer>
          </ExpandedContent>
        )}
      </>
    </div>
  );
};

const SkillItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spacing(1)};
`;

const GridContaienr = styled.div`
  display: grid;
  grid-gap: ${Spacing(2)};
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
`;

const InvitedContainer = styled.div<{ isSpaceBetween?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${Spacing(1)};
  width: 100%;
`;

const FlexContainer = styled.div<{ isSpaceBetween?: boolean }>`
  display: flex;
  align-items: center;
  gap: 2px;
  width: 100%;
`;

const RefferalImg = styled.img`
  height: 12px;
  width: auto;
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DOT]};
`;

const SkillContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(1)};
  align-items: start;
`;

const GiftContainer = styled.div`
  display: flex;
  gap: ${Spacing(1)};
  align-items: center;
  flex-wrap: wrap;
`;

const ChipContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: ${Spacing(3)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  gap: ${Spacing(2)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BLOCK]}px;
`;

const ExpandedContent = styled.div`
  grid-column: span 3;
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: ${Spacing(2)};
  align-items: center;
  transition: height 0.3s ease;
  margin-bottom: 12px;
`;

const RoleContainer = styled.div`
  display: grid;
  justify-self: end;
  min-width: 82px;
  text-align: end;
  align-self: center;
`;

const RoleButtonContainer = styled.div`
  display: grid;
  justify-self: end;

  position: absolute;
  right: 8px;
  max-width: 95px;
  top: 50%;
  transform: translateY(-50%);
`;

const UserContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
`;

const InfoContainer = styled.div`
  display: flex;
  text-align: start;
  flex-direction: column;
  gap: ${Spacing(1)};
`;

const Container = styled.div<{ isOpen: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 120px;
  padding: ${Spacing(5)} 0;
  align-items: center;

  position: relative;

  text-align: end;
  cursor: pointer;
  justify-content: center;
  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]},
    height 0.3s ease;

  &:hover {
    > *:not(${RoleButtonContainer}) {
      opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
    }
  }
`;
