import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { TextElem } from '../../../common/text';
import { Spacing } from '../../../theme';

import { WITHDRAW_ITEM_DATA_INTER } from '../constant';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import {
  USER_WITHDRAW_STATUS,
  USER_WITHDRAW_TYPE,
} from '../../withdraw-list/constant';
import { GIFT_DATA } from '../../gift-user-list/constant';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
} from '../../../theme/value';
import { COLOR_ENUM } from '../../../theme/color';
import CloseSvg from '../../../asset/svg/close.svg';

export const CardWithdraw: React.FC<{
  data: WITHDRAW_ITEM_DATA_INTER;
}> = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);


  return (
    <>
      <Container onClick={openModal} className="user_container">
        <BalanceContainer>
          {data.type === USER_WITHDRAW_TYPE.MONEY && (
            <TextElem
              color="white"
              size="semiSmall"
              type="semi-bold"
              oneLine
              tid={`$ ${data.amount}`}
            />
          )}
          {data.type === USER_WITHDRAW_TYPE.GIFT && (
            <TextElem
              color="white"
              size="semiSmall"
              type="semi-bold"
              oneLine
              tid={`${GIFT_DATA[data.gift].title}`}
            />
          )}
          <TextElem
            color="textSecondary"
            size="verySmall"
            type="semi-bold"
            tid="WITHDRAW.LIST.DATE"
            tvalue={{ date: data.createDate }}
          />
        </BalanceContainer>

        <RoleContainer>
          <Chip status={data.status.value as USER_WITHDRAW_STATUS}>
            <TextElem
              color="white"
              size="small"
              type="medium"
              tid={data.status.label}
            />
          </Chip>
        </RoleContainer>
      </Container>
      {isModalOpen && (
        <ModalBackdrop onClick={closeModal}>
          <ModalContainer onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={closeModal}>
              <img src={CloseSvg} />
            </CloseButton>
            <ModalContent>
              <TextElem
                color="toggleHandle"
                size="medium"
                type="medium"
                tid="WITHDRAW.USER.MODAL.TITLE"
              />
              <BalanceModal>
                <BalanceContainer>
                  {data.type === USER_WITHDRAW_TYPE.MONEY ? (
                    <TextElem
                      color="white"
                      size="semiSmall"
                      type="semi-bold"
                      tid={`$ ${data.amount}`}
                    />
                  ) : (
                    <TextElem
                      color="white"
                      size="semiSmall"
                      type="semi-bold"
                      tid={`${GIFT_DATA[data.gift].title}`}
                    />
                  )}
                  <TextElem
                    color="textSecondary"
                    size="small"
                    type="medium"
                    tid="WITHDRAW.LIST.DATE"
                    tvalue={{ date: data.createDate }}
                  />
                </BalanceContainer>

                <RoleContainer>
                  <Chip status={data.status.value as USER_WITHDRAW_STATUS}>
                    <TextElem
                      color="white"
                      size="small"
                      type="medium"
                      tid={data.status.label}
                    />
                  </Chip>
                </RoleContainer>
              </BalanceModal>
              <InfoContainer>
                <TextElem
                  size="small"
                  type="medium"
                  color="textSecondary"
                  tid="WITHDRAW.USER.MODAL.ADDRESS"
                />
                <TextElem
                  tid={data?.address || data?.wallet}
                  type="semi-bold"
                  color="textThird"
                  size="alert"
                />
              </InfoContainer>
              <InfoContainer>
                <TextElem
                  tid="WITHDRAW.USER.MODAL.TYPE"
                  size="small"
                  type="medium"
                  color="textSecondary"
                />
                <TextElem
                  tid={data.type}
                  type="semi-bold"
                  color="textThird"
                  size="alert"
                />
              </InfoContainer>
            </ModalContent>
          </ModalContainer>
        </ModalBackdrop>
      )}
    </>
  );
};

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 100;
`;

const ModalContainer = styled.div`
  width: 100%;
  height: 70%;
  background: white;
  border-top-left-radius: ${Spacing(4)};
  border-top-right-radius: ${Spacing(4)};
  padding: ${Spacing(6)} ${Spacing(5)} ${Spacing(12)} ${Spacing(5)};
  overflow-y: auto;
  animation: slideUp 0.3s ease-out;
  position: relative;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};

  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 201;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const BalanceModal = styled.div`
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  display: flex;
  justify-content: space-between;
  padding: ${Spacing(4)};
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
`;

const Chip = styled.div<{ status: USER_WITHDRAW_STATUS }>`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.TAG]}px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Spacing(2)};

  ${({ status }) => {
    if (status === USER_WITHDRAW_STATUS.PENDING) {
      return css`
        background-color: rgba(69, 172, 229, 0.3);

        span {
          color: #45ace5;
        }
      `;
    }

    if (status === USER_WITHDRAW_STATUS.COMPLETE) {
      return css`
        background-color: rgba(54, 178, 134, 0.3);

        span {
          color: #36b286;
        }
      `;
    }

    if (status === USER_WITHDRAW_STATUS.CANCEL) {
      return css`
        background-color: rgba(242, 72, 72, 0.3);

        span {
          color: #f24848;
        }
      `;
    }

    if (status === USER_WITHDRAW_STATUS.FOR_PAYMENT) {
      return css`
        background-color: rgba(209, 121, 38, 0.3);

        span {
          color: #d17926;
        }
      `;
    }
  }}
`;

const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  gap: ${Spacing(1)};
`;

const RoleContainer = styled.div`
  display: grid;
  justify-self: end;
  min-width: 82px;
  text-align: end;
  align-self: center;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;

  text-align: end;
  cursor: pointer;
  transition: all ${VALUE_TRANSITION_DATA[VALUE_OPACITY_ENUM.DEFAULT]};

  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;
