import React from 'react';

import styled from 'styled-components';
import RightArrow from '../../asset/svg/common/rigth-arrow.svg';
import SettingSvg from '../../asset/svg/setting.svg';
import userDefaultImg from '../../asset/svg/common/user.svg';

import { DATA_INTER } from './constant';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { Spacing } from '../../theme';
import { TextElem } from '../../common/text';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../theme/value';
import { useHistory } from 'react-router';
import { PROFILE_UPDATE_PAGE_PATH } from '../../page/profile-update';
import { LEVEL_GIFT_PAGE_PATH } from '../../page/level-gift';

export const Component: React.FC<{
  data?: DATA_INTER;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  photo?: string;
  photoIsLoading: boolean;
}> = ({ data, isLoading, photo, photoIsLoading }) => {
  const history = useHistory();

  if (isLoading) {
    return <></>;
  }

  const first_name = data?.first_name;
  const id = data?.id;
  const last_name = data?.last_name;

  const profilePhoto = photoIsLoading
    ? userDefaultImg
    : photo || userDefaultImg;

  const redirect = (path: string) => {
    history.push(path);
  };

  return (
    <Container>
      <FlexContainer>
        <ImgStyled src={profilePhoto} />
        <InfoContainer>
          <TextHeightStyled
            size="semiSmall"
            color="white"
            type="semi-bold"
            tid={`ID ${id}`}
          />
          <NameContainer>
            <TextElem
              color="textSecondary"
              size="small"
              type="medium"
              tid={first_name}
            />
            {'  '}
            <TextElem
              color="textSecondary"
              size="small"
              type="medium"
              tid={last_name}
            />
          </NameContainer>
        </InfoContainer>
      </FlexContainer>
      <FlexContainer>
        <ArrowContainer onClick={() => redirect(LEVEL_GIFT_PAGE_PATH)}>
          <TextElemStyled
            color="white"
            size="semiSmall"
            type="semi-bold"
            tid="ANALITIC.ACCOUNT.ALL"
          />
          <img src={RightArrow} />
        </ArrowContainer>
        {/* <OptionButton
          onClick={() => redirect(PROFILE_UPDATE_PAGE_PATH)}
          src={SettingSvg}
        /> */}
      </FlexContainer>
    </Container>
  );
};

const NameContainer = styled.div`
  display: flex;
  gap: 4px;
`;

const TextHeightStyled = styled(TextElem)`
  line-height: 1em;
`;

const TextElemStyled = styled(TextElem)`
  white-space: nowrap;
`;

const OptionButton = styled.img`
  width: 20px;
  height: 20px;
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  cursor: pointer;

  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spacing(2)};
`;

const ImgStyled = styled.img`
  border-radius: 100%;
  height: 38px;
  width: 38px;
`;

const ArrowContainer = styled.div`
  display: flex;
  gap: ${Spacing(1)};
  cursor: pointer;
  width: 100%;

  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.BUTTON]};
  }
`;

const InfoContainer = styled.div`
  display: flex;
  text-align: start;
  flex-direction: column;
  gap: 2px;
`;

const Container = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  padding: ${Spacing(5)} ${Spacing(4)};
  align-items: center;
  justify-content: space-between;
  border-top-right-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.DEFAULT
  ]}px;
  border-top-left-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.DEFAULT
  ]}px;
`;
